import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { csrdRoutePaths } from 'core/lib/routePaths/csrdRoutePaths';
import { RouteLoader } from '#components/Loader/RouteLoader';

const Csrd = lazy(() => import('csrd/central-routes/csrd-routes'));

const CSRDHome = lazy(() =>
  import('csrd/central-routes/csrd-routes').then((module) => ({
    default: module.HomeRoute,
  })),
);

const About = lazy(() =>
  import('csrd/central-routes/csrd-routes').then((module) => ({
    default: module.AboutRoute,
  })),
);

const Checkout = lazy(() =>
  import('csrd/central-routes/csrd-routes').then((module) => ({
    default: module.CheckoutRoute,
  })),
);

const Confirmation = lazy(() =>
  import('csrd/central-routes/csrd-routes').then((module) => ({
    default: module.ConfirmationRoute,
  })),
);

export const CSRDRouteTree = (
  <Route
    path={csrdRoutePaths.HOME}
    element={
      <Suspense fallback={<RouteLoader />}>
        <Csrd />
      </Suspense>
    }
  >
    <Route index element={<CSRDHome />} />,
    <Route path={csrdRoutePaths.ABOUT} element={<About />} />
    <Route path={csrdRoutePaths.CHECKOUT} element={<Checkout />} />,
    <Route path={csrdRoutePaths.CONFIRMATION} element={<Confirmation />} />
  </Route>
);

export const preloadCSRD = () => {
  //Import default export to trigger preload
  void import('csrd/central-routes/csrd-routes');
};
