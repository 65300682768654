import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ContentProvider from 'core/lib/Content/ContentProvider';
import { RoleIndustrySurvey } from 'core/lib/RoleIndustry';
import { useServices } from 'core/lib/Services';
import { TopMenuStore } from 'core/lib/Stores';
import { HeaderMenu } from 'core/lib/HeaderMenu';
import { Footer } from 'core/lib/Footer';
import {
  colors,
  SnackbarProvider,
  PageContainer,
  Snackbar,
} from 'component-library/themes/stena-recycling/colors';
import { AppContainer } from 'component-library/components/Layout/AppContainer';
import { Spinner } from 'component-library/components/Feedback/Spinner';
import { Preloader } from '../Preloader/Preloader';
import { RouteLoader } from '../Loader/RouteLoader';
import { HideOnPrint } from './styles';

const AppWrapper = () => {
  const { flagsReady } = useFlagsStatus();

  return (
    <ContentProvider applicationName="all">
      <SnackbarProvider>
        <AppContainer
          header={<TopMenu />}
          footer={<FooterWrap />}
          backgroundColor={colors.secondary.beigeTint1}
        >
          <Snackbar />
          {!flagsReady && (
            <PageContainer>
              <Spinner size="xlarge" />
            </PageContainer>
          )}
          {flagsReady && (
            <>
              <RoleIndustrySurvey />
              <Preloader />
              <Outlet />
            </>
          )}
        </AppContainer>
      </SnackbarProvider>
    </ContentProvider>
  );
};

const getActiveServiceName = (pathname: string) => {
  if (pathname === '/' || pathname.startsWith('/support')) {
    return 'touchpointx';
  } else if (pathname.startsWith('/pickups')) {
    return 'services.order.pickup.name';
  } else if (pathname.startsWith('/followup')) {
    return 'services.follow.up.name';
  } else if (pathname.startsWith('/co2impact')) {
    return 'co2_impact';
  } else if (pathname.startsWith('/csrd')) {
    return 'csrd';
  } else if (pathname.startsWith('/admin') && !pathname.startsWith('/admin/profile')) {
    return 'usermanagement';
  } else {
    return 'touchpointx';
  }
};

const TopMenu = () => {
  const { services } = useServices();
  const subLinks = TopMenuStore.useStoreState((state) => state.subLinks);
  const subButton = TopMenuStore.useStoreState((state) => state.subButton);

  const { t } = useTranslation();

  const { pathname } = useLocation();
  const activeServiceName = getActiveServiceName(pathname);

  const serviceNames = services.reduce((prev: Record<string, string>, curr) => {
    prev[curr.name] = t(curr.name);

    return prev;
  }, {});

  return (
    <HideOnPrint>
      <HeaderMenu
        activeServiceName={activeServiceName}
        text={{
          homeLink: t('home.link'),
          signOut: t('sign.out'),
          contactSupport: t('contact.support'),
          userProfile: t('user.profile'),
          userManagement: t('user.management'),
          co2Impact: t('services.co2impact.name'),
          csrd: t('services.csrd.name'),
        }}
        subLinks={subLinks}
        subButton={subButton}
        serviceNames={serviceNames}
      />
    </HideOnPrint>
  );
};

export const FooterWrap = () => {
  const { t } = useTranslation();

  return (
    <HideOnPrint>
      <Footer
        marginTop="0"
        text={{
          privacyInformation: t('footer.privacy.information'),
          support: t('footer.customer.support'),
          releaseNotes: t('footer.release.notes'),
        }}
      />
    </HideOnPrint>
  );
};

export default withAuthenticationRequired(AppWrapper, {
  onRedirecting: () => <RouteLoader />,
});
