import { useAuth0 } from '@auth0/auth0-react';
import { useTransactionId } from '../hooks/useTransactionId';
import { customerPortalApi } from './customerPortalApi';

type FetchFn<TData> = ({
  url,
  options,
  type,
}: {
  url: string;
  options?: RequestInit;
  type?: 'json' | 'text';
}) => Promise<TData>;

type Fn<TData, TReturn, TQueryFnData> = (
  fetchFn: FetchFn<TData>,
  queryFnData: TQueryFnData,
) => TReturn;

/**
 * Higher-order function (HOC) that provides a fetch function with pre-configured
 * authentication and transaction ID to access Customer Portal API.
 *
 * You can use this as a wrapper for a hook that calls a Tanstack Query function.
 *
 * https://tanstack.com/query/latest/docs/framework/react/overview
 *
 * @template TData - The type of data expected from the API.
 * @template TReturn - The return type of the provided Tanstack Query function.
 * @template TQueryFnData - The type of data passed to the provided function.
 *
 * @param fn - A function that takes a fetch function and optional query data, and passes that to a Tanstack Query function.
 *
 * @returns A function that takes optional query data and returns the result of the provided Tanstack Query function.
 *
 * @example
 * ```typescript
 * // A simple GET request
 * type ApiResponseData = { name: string }[];
 * type TanstackQueryReturnType = UseQueryResult<ApiResponseData>;
 *
 * const useMyApiCall = WithCustomerPortalApi<ApiResponseData, TanstackQueryReturnType>((fetchFn) => {
 *   const url = '/api/data';
 *
 *   return useQuery({
 *     queryKey: ['my-api'],
 *     queryFn: () =>
 *       fetchFn({
 *         url,
 *       }),
 *   });
 * });
 *
 * const { data, isPending, isError } = useMyApiCall();
 *
 * if(isPending) {
 *   return <div>Loading...</div>
 * }
 *
 * if(isError) {
 *   return <div>Error!</div>
 * }
 *
 * return <div>My name: {data.name}</div>
 * ```
 *
 * @example
 * ```typescript
 * // A more complex POST request using mutation
 * type ApiResponseData = { name: string }[];
 * type HookProps = { endpoint: string };
 * type TanstackQueryVariables = { id: number };
 * type TanstackQueryReturnType = UseMutationResult<ApiResponseData, DefaultError, TanstackQueryVariables>;
 *
 * const useMyApiCall = WithCustomerPortalApi<ApiResponseData, TanstackQueryReturnType, HookProps>((fetchFn, queryData) => {
 *   const url = `/api/data/${queryData.endpoint}`;
 *
 *   const mutation = useMutation<ApiResponseData, DefaultError, TanstackQueryVariables>({
 *     mutationFn: (event) =>
 *       fetchFn({
 *         url: `${url}/${event.id}`,
 *         options: {
 *           method: "POST",
 *           body: JSON.stringify(event),
 *         },
 *       }),
 *   });
 *
 *   return mutation;
 * });
 *
 * const myApiCallMutation = useMyApiCall({ endpoint: 'documents' });
 *
 * myApiCallMutation.mutate({ id: 123 });
 * ```
 */
export const WithCustomerPortalApi =
  <TData, TReturn = unknown, TQueryFnData = unknown>(
    fn: Fn<TData, TReturn, TQueryFnData>,
    mockFn?: typeof customerPortalApi<TData>,
  ) =>
  (queryFnData?: TQueryFnData) => {
    const { getAccessTokenSilently } = useAuth0();
    const accessToken = getAccessTokenSilently();
    const transactionId = useTransactionId();

    if (queryFnData) {
      if (mockFn) {
        return fn(
          ({ url, options, type }) =>
            mockFn({
              url,
              options,
              accessToken,
              transactionId,
              type,
            }),
          queryFnData,
        );
      }

      return fn(
        ({ url, options, type }) =>
          customerPortalApi({
            url,
            options,
            accessToken,
            transactionId,
            type,
          }),
        queryFnData,
      );
    }

    if (mockFn) {
      return fn(
        ({ url, options, type }) =>
          mockFn({
            url,
            options,
            accessToken,
            transactionId,
            type,
          }),
        {} as TQueryFnData,
      );
    }

    return fn(
      ({ url, options, type }) =>
        customerPortalApi({
          url,
          options,
          accessToken,
          transactionId,
          type,
        }),
      {} as TQueryFnData,
    );
  };
