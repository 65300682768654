import { useMemo } from 'react';
import { type CountryCode } from '@stenarecycling/customer-portal-types';
import { useProfileV2 } from '#lib/Profile/useProfileV2';

export type SupportedCountries = {
  sweden?: boolean;
  denmark?: boolean;
  norway?: boolean;
  finland?: boolean;
  poland?: boolean;
};

export const countries = [
  {
    code: 'SE',
    name: 'sweden',
  },
  {
    code: 'DK',
    name: 'denmark',
  },
  {
    code: 'NO',
    name: 'norway',
  },
  {
    code: 'FI',
    name: 'finland',
  },
  {
    code: 'PL',
    name: 'poland',
  },
] as const;

export const useCountries = () => {
  const { basicProfile } = useProfileV2();

  const supportedCountries = useMemo(() => {
    if (!basicProfile?.aggregatedPermissions) {
      return {
        sweden: false,
        denmark: false,
        norway: false,
        finland: false,
        poland: false,
      };
    }

    const perms = basicProfile.aggregatedPermissions;

    return perms.markets;
  }, [basicProfile]);

  const supportedCountryCodes = useMemo(() => {
    const countryCodes: CountryCode[] = [];

    if (supportedCountries.sweden) {
      countryCodes.push('SE');
    }
    if (supportedCountries.denmark) {
      countryCodes.push('DK');
    }
    if (supportedCountries.norway) {
      countryCodes.push('NO');
    }
    if (supportedCountries.finland) {
      countryCodes.push('FI');
    }
    if (supportedCountries.poland) {
      countryCodes.push('PL');
    }

    return countryCodes;
  }, [supportedCountries]);

  return {
    supportedCountries,
    supportedCountryCodes,
  };
};
