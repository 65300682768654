import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { useProfileV2 } from '#lib/Profile';
import { getEnvironment } from '#lib/utils/environment';
import { AnnounceKitComponent } from '../AnnounceKit';
import { DropDownMenu } from '../DropDownMenu';
import { type MenuService, useServices } from '../Services/useServices';

import LogoIcon from './LogoIcon';
import MenuLinks from './MenuLinks';
import { MobileMenu } from './MobileMenu';
import { MobileSubMenu } from './MobileSubMenu';
import {
  Nav,
  MenuWrapper,
  SubNav,
  NavWrapper,
  SubNavWrapper,
  AnnounceKitWrapper,
  IconsWrapper,
  LogoWrapper,
  WatermarkWrapper,
} from './styles';
import SubMenu from './SubMenu';
import UserIconButton from './UserIconButton';
import { type SubButtonProps, type SubLinkProps, type TextType } from './types';

type HeaderMenuProps = {
  /** The name of the service implementing the Menu. It's used to highlight the correct active link in the menu */
  activeServiceName?: string;
  /** Array of objects used to render subMenu links for navigation in your application.*/
  subLinks?: SubLinkProps[];

  subButton?: SubButtonProps;

  //** The translated text that the menu need. The package exports the translation files needed but it's upp to your application to use something like i18n. */
  text: TextType;

  //** Translation object for all the services */
  serviceNames?: Record<string, string>;
};

export const HeaderMenu = ({
  activeServiceName,
  subLinks = [],
  subButton,
  text,
  serviceNames,
}: HeaderMenuProps) => {
  const { basicProfile } = useProfileV2();
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const [showMenu, setShowMenu] = useState(false);
  const [showServiceLinks, setShowServiceLinks] = useState(false);
  const { services } = useServices();
  const [filteredServices, setFilteredServices] = useState<MenuService[]>([]);

  useEffect(() => {
    const filtered = services.filter((s) => s.showInTopMenu);

    setFilteredServices(filtered);
    setShowServiceLinks(true);
  }, [services]);

  const handleOnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    //This is needed because in React18 addEventListener('click') gets triggered in DropDownMenu
    e.stopPropagation();
    if (!isAuthenticated) {
      await loginWithRedirect();
    }
    setShowMenu((prevState) => !prevState);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const watermark = useMemo(() => {
    const environment = getEnvironment();

    return {
      show: environment === 'local' || environment === 'development' || environment === 'test',
      text: environment.toLocaleUpperCase(),
    };
  }, []);

  return (
    <>
      {watermark.show && <WatermarkWrapper>{watermark.text}</WatermarkWrapper>}
      <MenuWrapper>
        <NavWrapper>
          <Nav>
            <LogoWrapper data-testid="header-logo-wrapper">
              <MobileMenu
                profile={basicProfile}
                activeLink={activeServiceName}
                allServices={filteredServices}
                serviceNames={serviceNames}
                text={text}
              />
              <LogoIcon />
            </LogoWrapper>
            {showServiceLinks && (
              <>
                <MenuLinks
                  activeLink={activeServiceName}
                  profile={basicProfile}
                  allServices={filteredServices}
                  text={text}
                  serviceNames={serviceNames}
                />
                <IconsWrapper>
                  <AnnounceKitWrapper>
                    <AnnounceKitComponent></AnnounceKitComponent>
                  </AnnounceKitWrapper>
                  {basicProfile && (
                    <UserIconButton onClick={handleOnClick} data-testid="profile-menu" />
                  )}
                  {showMenu && isAuthenticated && (
                    <DropDownMenu
                      text={text}
                      closeMenu={closeMenu}
                      logout={async () => {
                        await logout({ logoutParams: { returnTo: window.location.origin } });
                      }}
                    />
                  )}
                </IconsWrapper>
              </>
            )}
          </Nav>
        </NavWrapper>
        {subLinks.length > 0 && isAuthenticated && (
          <>
            <SubNavWrapper>
              <SubNav>
                <SubMenu subLinks={subLinks} subButton={subButton} />
              </SubNav>
            </SubNavWrapper>
            <MobileSubMenu subLinks={subLinks} subButton={subButton} />
          </>
        )}
      </MenuWrapper>
    </>
  );
};
